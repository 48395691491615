import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Card from "../components/Card/Card";
import AaJumbotron from "../components/AaJumbotron/AaJumbotron";

export default class Infos extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <Layout>
        <div>
          <section className="news">
            <div
              className="overlayhero tophero"
            >
              <AaJumbotron
                title="Latest Stories"
              />
            </div>
            <div className="blogCards">
              {posts.map(({ node: post }) => (
                <Card
                key={post.id}
                  id={post.id}
                  buttonLink={post.fields.slug}
                  title={post.frontmatter.title}
                  date={post.frontmatter.date}
                  text={post.excerpt}
                />
              ))}
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

Infos.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export const pageQuery = graphql`
  query InfosQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
